// Import Roboto font
@import url('https://fonts.googleapis.com/css?family=Roboto:300');
@import "./breakpoints.scss";

/** CSS Variables **/
:root {
  --unit-xxs: 1px;
  --unit-xs: 2px;
  --unit-s: 4px;
  --unit-s-h: 6px;
  --unit: 8px;
  --unit-m-h: 12px;
  --unit-l: 16px;
  --unit-l-h: 24px;
  --unit-xl: 32px;
  --unit-xl-h: 48px;
  --unit-xxl: 64px;

  --textXXXSmall: 0.6rem;
  --textXXSmall: 0.7rem;
  --textXSmall: 0.8rem;
  --textSmall: 0.9rem;
  --text: 1rem;
  --textMedium: 1.1rem;
  --textLarge: 1.2rem;
  --textXLarge: 1.5rem;
  --textXXLarge: 2rem;
  --textXXXLarge: 2.5rem;

  .light-mode {
    --color-primary: #4e3dff; 
    --color-danger: #eb445a;
    --color-border-soft: #eaeaea;
    --color-border-hard: #cecece;
    --color-text: #000000;
    --color-subtext: #3c3c3c;
    --color-placeholder: #eaeaea;
  }
  .dark-mode {
    --color-primary: #8377ff; 
    --color-danger: #eb445a;
    --color-border-soft: #545454;
    --color-border-hard: #545454;
    --color-text: #ffffff;
    --color-subtext: #c1c1c1;
    --color-placeholder: #545454;
  }

  /** Custom colors **/
  --color-saved: #0a8fd0;
  --color-liked: #ff3232;
  --color-crown: #c1c132;
  --color-reposted: #0a8fd0;

  /** Social media **/
  --color-facebook: #3b5998;
  --color-facebook-light: #0077e2;
  --color-instagram: grey;
  --color-twitter: #00aced;
  --color-youtube: #FF0000;
  --color-soundcloud: #ff7700;
  --color-mixcloud: #273a4b;
  --color-bandcamp: #629aa9;
  --color-spotify: #1db954;
  --color-discogs: #333333;
  --color-ra: black;

  .hidden-xs {
    @include media("<=xs") {
      display: none !important;
    }
  }
  .visible-xs {
    @include media(">=xs") {
      display: none !important;
    }
  }
  .hidden-s {
    @include media("<=s") {
      display: none !important;
    }
  }
  .visible-s {
    @include media(">=s") {
      display: none !important;
    }
  }
  .hidden-m {
    @include media("<=m") {
      display: none !important;
    }
  }
  .visible-m {
    @include media(">=m") {
      display: none !important;
    }
  }
  .hidden-l {
    @include media("<=l") {
      display: none !important;
    }
  }
  .visible-l {
    @include media(">=l") {
      display: none !important;
    }
  }
}
